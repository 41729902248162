<template>
    <div>
        <PageHeaderLayout>
            <div class="main-page-content">
                <ApeTable ref="apeTable" :data="importQuestionList" :columns="columns" :loading="loadingStatus"
                          :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
                    <el-table-column label="文件下载">
                        <template slot-scope="scope">
                            <a :href="scope.row.file_url" target="_blank">下载</a>
                        </template>
                    </el-table-column>

                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <div :class="{ 'color-red':  scope.row.status == 'fail', 'color-green':scope.row.status == 'done', 'color-blue':scope.row.status == 'doing',}">{{ scope.row.status_alias }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="创建时间">
                        <template slot-scope="scope">
                            <span>{{ scope.row.created_at }}</span>
                        </template>
                    </el-table-column>
                </ApeTable>
            </div>
        </PageHeaderLayout>
    </div>
</template>

<script>

    const DEL = "del";

    import PageHeaderLayout from '@/layouts/PageHeaderLayout'
    import ApeTable from '@/components/ApeTable'
    import ApeDrawer from '@/components/ApeDrawer'
    import Sortable from 'sortablejs'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            PageHeaderLayout,
            ApeTable,
            ApeDrawer,
        },
        data() {
            return {
                loadingStatus: true,
                columns: [
                    {
                        title: '文件类型',
                        value: 'type_alias',
                    }
                ],
                // 表格列表数据
                importQuestionList: [],
                // 分页信息
                pagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },
                // 表单结构
                formData: {
                    name: '',
                    status: '0',
                },
                // 表单验证
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'}
                    ],
                    status: [
                        {required: true, message: '请选择题库状态', trigger: 'blur'}
                    ]
                },
                // 抽屉数据
                drawerData: {
                    visible: false,
                    loading: true,
                    loading_text: '玩命加载中……',
                    title: '',
                    width_height: '560px',
                },
                typeList: [],
                //搜索表单
                searchForm: [
                    {
                        title: "名称",
                        value: "name"
                    }
                ],

                //自动填充数据
                restaurants: [],
            }
        },
        computed: {
            ...mapGetters(['userPermissions', 'buttonType'])
        },
        methods: {
            // 切换页码操作
            async switchPaging() {
                this.resetImportQuestionList()
            },

            // 响应添加按钮
            async addButton() {
                this.drawerData.loading_text = '玩命加载中……';
                this.drawerData.visible = true;
                this.drawerData.title = '添加题库';
                this.typeList = [];
                this.$nextTick(() => {
                    this.drawerData.loading = false
                })
            },

            // 响应编辑按钮
            async editButton(id) {
                this.drawerData.loading_text = '玩命加载中……';
                this.drawerData.visible = true;
                this.drawerData.title = '编辑题库(ID：' + id + ')';
                this.typeList = [];
                let {info} = await this.$api.getQuestionBankInfo(id);
                this.formData = info;
                this.drawerData.loading = false
            },

            // 处理抽屉关闭
            drawerClose() {
                this.resetFormData();
                this.drawerData.visible = false;
                this.drawerData.loading = true
            },

            // 处理抽屉确认
            async drawerConfirm() {
                // 调用组件的数据验证方法
                this.$refs['questionBankForm'].validate((valid) => {
                    if (valid) {
                        this.formSubmit()
                    } else {
                        this.$message.error('数据验证失败，请检查必填项数据！')
                    }
                })
            },

            // form数据提交，请求接口
            async formSubmit() {
                this.drawerData.loading_text = '玩命提交中……';
                this.drawerData.loading = true;
                let id = await this.$api.saveQuestionBank(this.formData);
                this.$nextTick(() => {
                    this.drawerData.visible = false
                });
                this.$nextTick(() => {
                    if (id) {
                        this.resetImportQuestionList()
                    }
                });
                this.$nextTick(() => {
                    this.$message.success('保存成功!')
                });
                this.resetFormData()
            },

            // 相应删除按钮
            async deleteButton(id) {
                let info = await this.$api.deleteQuestionBank(id);
                if (info == 'ok') {
                    this.$nextTick(() => {
                        this.$message.success("删除成功");
                        this.resetImportQuestionList('del')
                    })
                } else {
                    this.$message.error(info)
                }
            },

            // 初始化数据
            resetFormData() {
                // 初始化form表单
                this.formData = {
                    name: '',
                    status: '0',
                };

                this.$nextTick(() => {
                    this.$refs['questionBankForm'].resetFields();
                })
            },

            // 初始化题库列表
            async resetImportQuestionList(type) {
                this.loadingStatus = true;
                let inputData = this.$refs['apeTable'].getPagingInfo(type);

                let {list, pages} = await this.$api.getImportQuestionList(inputData);
                this.importQuestionList = [];
                this.$nextTick(() => {
                    this.importQuestionList = list
                });
                this.pagingData.total = pages.total;
                this.pagingData.offset = pages.offset;
                this.loadingStatus = false
            },

            // 拖拽排序数据提交，请求接口
            async dragSortSubmit() {
                // let info = await this.$api.orderCarousel(orderIds)
                // if (info == 'ok') {
                //     this.$nextTick(() => {
                //         this.initCarouselList()
                //     })
                // }
                this.$message.success('保存成功!')
            },

            // 表格拖拽排序，同层级移动有效果
            dragSort() {
                const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
                this.sortable = Sortable.create(el, {
                    handle: ".drag-handle",
                    setData: function (dataTransfer) {
                        dataTransfer.setData('Text', '')
                    },
                    onEnd: () => {
                        let Ids = [];
                        let tmp = el.querySelectorAll('.drag-handle');
                        for (let i = 0, len = tmp.length; i < len; i++) {
                            Ids.push(tmp[i].dataset.id)
                        }
                        this.dragSortSubmit(Ids)
                    },
                })
            },

            //搜索
            onSearchClick(formData) {
                this.$refs['apeTable'].resetCurPageSize();
                this.resetImportQuestionList(DEL, formData);
            },

            //获取题库所有名称
            async getAllQuestionBankName() {
                let inputData = {
                    page_size: 1000,
                    current_page: 1
                };
                let {list, pages} = await this.$api.getQuestionBankList(inputData);

                this.$nextTick(() => {
                    if (list.length < 0) {
                        return;
                    }

                    for (let item of list) {
                        let itemObjt = {
                            value: item.name,
                            id: item.id
                        };

                        this.restaurants.push(itemObjt);
                    }

                });
            },

            //搜索重置
            onResetSearch() {
                this.resetImportQuestionList();
            }
        },
        mounted() {
            this.resetImportQuestionList();
        },
    }
</script>

<style lang="stylus" scoped>
    .table-header
        margin-bottom 12px

    .drag-handle
        font-size 24px
        cursor pointer

    .el-input-group__prepend, .el-input-group__append
        background #ffffff
        padding 0 12px

    .el-input-group__append
        color #ffffff
        background #1890ff

    .el-popover .el-checkbox-group .el-checkbox
        margin-left 0
        margin-right 12px

    /*.el-select > .el-input*/
    /*width 373.33px*/

    .el-radio.is-bordered
        width 100px

    .el-color-picker
        position absolute

    .color-red
        color red;

    .color-blue
        color #4FA2FF

    .color-green
        color #67C23A
</style>
